import { computed } from "@vue/composition-api"
import uuidByString from "uuid-by-string"

import useStore from "@/use/useStore"

export default function useJeopardAiId() {
  const { store } = useStore()

  const id = computed<string>(() => {
    const mission = store.getters.getCurrentMission
    const activityId = mission?.activityId ?? mission?.id ?? ""
    const roomId = store.getters.gameID
    return uuidByString(activityId + roomId, 5)
  })

  return { id }
}
