import { initializeApp } from "firebase/app"
import { getDatabase, connectDatabaseEmulator } from "firebase/database"
import { getAuth } from "firebase/auth"

const firebaseConfig =
  process.env.VUE_APP_MODE === "staging"
    ? {
        apiKey: "AIzaSyDVPZDSCvDyZBW_6MzYyB7pTYV6sQnx12o",
        authDomain: "the-go-game-jeopardai-staging.firebaseapp.com",
        databaseURL:
          "https://the-go-game-jeopardai-staging-default-rtdb.firebaseio.com",
        projectId: "the-go-game-jeopardai-staging",
        storageBucket: "the-go-game-jeopardai-staging.appspot.com",
        messagingSenderId: "921777286084",
        appId: "1:921777286084:web:e682b800b0dc23fb3bd291"
      }
    : {
        apiKey: "AIzaSyA--P7B2MnISnvlA9iRgIBGtSrdG7mdwmQ",
        authDomain: "the-go-game-jeopardai.firebaseapp.com",
        databaseURL:
          "https://the-go-game-jeopardai-default-rtdb.firebaseio.com",
        projectId: "the-go-game-jeopardai",
        storageBucket: "the-go-game-jeopardai.appspot.com",
        messagingSenderId: "962226246281",
        appId: "1:962226246281:web:70c34dee0aace034dc6c58"
      }

const app = initializeApp(firebaseConfig, "jeopardai")

export const auth = getAuth(app)

export const db = getDatabase(app)

if (location.hostname === "localhost") {
  connectDatabaseEmulator(db, "localhost", 9000)
}
