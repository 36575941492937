import useStore from "@/use/useStore"
import { computed } from "@vue/composition-api"
import { groupBy } from "lodash"

export default function useJeopardPredefined() {
  const { store } = useStore()
  const mission = computed(() => store.getters.getCurrentMission)

  const predefined = computed(() => mission.value?.predefined)
  const rounds = computed(() => mission.value?.rounds)
  const firstRound = computed(() => rounds.value?.round_1)
  const secondRound = computed(() => rounds.value?.round_2)
  const final = computed(() => rounds.value?.final)
  const finalQuestionsByCategory = computed(() =>
    groupBy(final.value.questions, "category")
  )

  function getFinalQuestion(category: string) {
    return finalQuestionsByCategory.value[category][0]
  }

  return {
    getFinalQuestion,
    final,
    secondRound,
    firstRound,
    finalQuestionsByCategory,
    predefined,
    mission
  }
}
