import {
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
  computed
} from "@vue/composition-api"

import { onValue } from "firebase/database"

import type { Unsubscribe, DatabaseReference } from "firebase/database"

import useJeopardAiAnswersRef from "./useJeopardAiAnswersRef"

import type { Answer } from "../types"

let unsubscribe: Unsubscribe

const { ref: answersRef } = useJeopardAiAnswersRef()

const count = ref(0)
const answers = ref<Answer[]>([])

const enabled = computed(() => count.value > 0)

const databaseRef = computed<DatabaseReference | undefined>(() => {
  if (enabled.value === false) return undefined

  return answersRef.value
})

watch(databaseRef, value => {
  if (unsubscribe != null) unsubscribe()
  if (value == null) return

  unsubscribe = onValue(value, snapshot => {
    const value: Record<string, Answer> = snapshot.val()
    answers.value = Object.entries(value ?? {}).map(([key, value]) => ({
      id: key,
      ...value
    }))
  })
})

export default function useJeopardAiAnswers() {
  onMounted(() => {
    count.value++
  })

  onBeforeUnmount(() => {
    count.value--
  })

  return { answers }
}
