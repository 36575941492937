import { computed } from "@vue/composition-api"

import { child } from "firebase/database"

import useJeopardAiBaseRef from "./useJeopardAiBaseRef"

export default function useJeopardAiAnswersRef() {
  const { ref: rootRef } = useJeopardAiBaseRef()
  const ref = computed(() => {
    if (rootRef.value == null) return undefined
    return child(rootRef.value, "answers")
  })

  return { ref }
}
