import { db } from "@/firebase"
import uuidByString from "uuid-by-string"
import useStore from "@/use/useStore"
import { computed } from "@vue/composition-api"

export default function useJeopardPredefinedRef() {
  const { store } = useStore()
  const mission = computed(() => store.getters.getCurrentMission)
  const roomID = computed(() => store?.getters.game?.id)
  const databaseRef = computed(() => {
    const { activityId } = mission.value
    const missionId = uuidByString(String(activityId), 5)
    return db.auxiliary().ref(`_room/${roomID.value}/jeopard/${missionId}`)
  })

  return {
    databaseRef
  }
}
