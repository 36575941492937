import { ref as databaseRef } from "firebase/database"
import { computed, ref } from "@vue/composition-api"
import { signInAnonymously } from "firebase/auth"

import type { DatabaseReference } from "firebase/database"

import { db as database, auth } from "../database"

import useJeopardAiId from "./useJeopardAiId"
import useJeopardPredefined from "./useJeopardPredefined"
import useJeopardPredefinedRef from "./useJeopardPredefinedRef"

const user = ref(undefined)

signInAnonymously(auth).then(value => {
  user.value = value
})

export default function useJeopardAiBaseRef() {
  const { id } = useJeopardAiId()
  const { databaseRef: predefinedRef } = useJeopardPredefinedRef()
  const { predefined } = useJeopardPredefined()

  const rootRef = computed<DatabaseReference | undefined>(() => {
    if (user.value == null) return undefined
    if (predefined.value) {
      return predefinedRef.value
    }
    return databaseRef(database, id.value)
  })

  return { ref: rootRef }
}
